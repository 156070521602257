<template>
    <div class="modal modal-lg fade" id="createPaymentModal" tabindex="-1" aria-labelledby="currenyModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
                
                <div class="card mb-4">
					<div class="card-header">
						<h4><i class="fa-solid fa-wallet me-2"></i>Payment Details</h4>
					</div>
					<div class="card-body gap-4">

						<h4 class="fs-5 fw-semibold">Saved Card (02)</h4>

						<div class="row justify-content-start g-3">
									<div class="col-xl-5 col-lg-6 col-md-6">
										<div class="card h-100">
											<div class="bg-dark p-4 rounded-3">
												<div class="d-flex justify-content-between align-items-start">
													<img class="img-fluid" src="assets/img/visa.png" width="55" alt="">
													<!-- Card action START -->
													<div class="dropdown">
														<a class="text-white" href="#" id="creditcardDropdown" role="button"
															data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
															<!-- Dropdown Icon -->
															<svg width="24" height="24" fill="none">
																<circle fill="currentColor" cx="12.5" cy="3.5" r="2.5"></circle>
																<circle fill="currentColor" opacity="0.5" cx="12.5" cy="11.5" r="2.5"></circle>
																<circle fill="currentColor" opacity="0.3" cx="12.5" cy="19.5" r="2.5"></circle>
															</svg>
														</a>
														<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="creditcardDropdown">
															<li><a class="dropdown-item" href="#"><i
																		class="bi bi-credit-card-2-front-fill me-2 fw-icon"></i>Edit card</a></li>
															<li><a class="dropdown-item" href="#"><i
																		class="bi bi-calculator me-2 fw-icon"></i>Currency converter</a></li>
														</ul>
													</div>
													<!-- Card action END -->
												</div>
												<h4 class="text-white fs-6 mt-4">**** **** **** 1569</h4>
												<div class="d-flex justify-content-between text-white mt-4">
													<div class="d-flex flex-column">
														<span class="text-md">Issued To</span>
														<span class="text-sm fw-medium text-uppercase">Daniel Duekoza</span>
													</div>
													<div class="d-flex text-end flex-column">
														<span class="text-md">Valid Thru</span>
														<span>12/2027</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-xl-5 col-lg-6 col-md-6">
										<div class="card h-100">
											<div class="bg-seegreen p-4 rounded-3">
												<div class="d-flex justify-content-between align-items-start">
													<img class="img-fluid" src="assets/img/card.png" width="55" alt="">
													<!-- Card action START -->
													<div class="dropdown">
														<a class="text-white" href="#" id="creditcardDropdown1" role="button"
															data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
															<!-- Dropdown Icon -->
															<svg width="24" height="24" fill="none">
																<circle fill="currentColor" cx="12.5" cy="3.5" r="2.5"></circle>
																<circle fill="currentColor" opacity="0.5" cx="12.5" cy="11.5" r="2.5"></circle>
																<circle fill="currentColor" opacity="0.3" cx="12.5" cy="19.5" r="2.5"></circle>
															</svg>
														</a>
														<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="creditcardDropdown1">
															<li><a class="dropdown-item" href="#"><i
																		class="bi bi-credit-card-2-front-fill me-2 fw-icon"></i>Edit card</a></li>
															<li><a class="dropdown-item" href="#"><i
																		class="bi bi-calculator me-2 fw-icon"></i>Currency converter</a></li>
														</ul>
													</div>
													<!-- Card action END -->
												</div>
												<h4 class="text-white fs-6 mt-4">**** **** **** 1563</h4>
												<div class="d-flex justify-content-between text-white mt-4">
													<div class="d-flex flex-column">
														<span class="text-md">Issued To</span>
														<span class="text-sm fw-medium text-uppercase">Daniel Duekoza</span>
													</div>
													<div class="d-flex text-end flex-column">
														<span class="text-md">Valid Thru</span>
														<span>12/2027</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-xl-2 col-lg-6 col-md-6">
										<div
											class="card d-flex align-items-center justify-content-center border br-dashed border-2 py-3 h-100">
											<div class="d-flex align-items-center justify-content-center">
												<a href="#" class="square--60 circle bg-light-primary text-primary fs-2" data-bs-toggle="modal"
													data-bs-target="#addcard"><i class="fa-solid fa-circle-plus"></i></a>
											</div>
										</div>
									</div>

						</div>
                        <a href="#" style="margin-top:2%" class="btn btn-md btn-primary fw-medium mb-0">Make Payment</a>
					</div>
				</div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'CreatePaymentModal',
        components:{

        }
    }
</script>