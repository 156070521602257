<template>
    <section class="pt-5 gray-simple position-relative">
			<div class="container">

				<div class="row align-items-center justify-content-center">
					<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
						<button class="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i
								class="fa-solid fa-gauge me-2"></i>Dashboard
							Navigation</button>
						<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
							id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
							<div class="offcanvas-header gray-simple">
								<h5 class="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
								<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
							</div>
							<div class="offcanvas-body p-0">
								<ul class="user-Dashboard-longmenu">
									<li><a href="my-profile.html"><i class="fa-regular fa-id-card me-2"></i>My Profile</a></li>
									<li><a href="my-booking.html"><i class="fa-solid fa-ticket me-2"></i>My Booking</a>
									</li>
									<li><a href="travelers.html"><i class="fa-solid fa-user-group me-2"></i>Travelers</a></li>
									<li><a href="payment-detail.html"><i class="fa-solid fa-wallet me-2"></i>Payment Details</a></li>
									<li class="active"><a href="my-wishlists.html"><i class="fa-solid fa-shield-heart me-2"></i>My
											Wishlist</a></li>
									<li><a href="settings.html"><i class="fa-solid fa-sliders me-2"></i>Settings</a></li>
									<li><a href="delete-account.html"><i class="fa-solid fa-trash-can me-2"></i>Delete Profile</a></li>
									<li><a href="login.html"><i class="fa-solid fa-power-off me-2"></i>Sign Out</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="row align-items-start justify-content-between gx-xl-4">

					<div class="col-xl-4 col-lg-4 col-md-12">
						<div class="card rounded-2 me-xl-5 mb-4">
							<div class="card-top bg-primary position-relative">
								<div class="position-absolute end-0 top-0 mt-4 me-3"><a href="login.html"
										class="square--40 circle bg-light-dark text-light"><i
											class="fa-solid fa-right-from-bracket"></i></a></div>
								<div class="py-5 px-3">
									<div class="crd-thumbimg text-center">
										<div class="p-2 d-flex align-items-center justify-content-center brd"><img src="assets/img/team-1.jpg"
												class="img-fluid circle" width="120" alt=""></div>
									</div>
									<div class="crd-capser text-center">
										<h5 class="mb-0 text-light fw-semibold">Adam K. Divliars</h5>
										<span class="text-light opacity-75 fw-medium text-md"><i
												class="fa-solid fa-location-dot me-2"></i>California, USA</span>
									</div>
								</div>
							</div>

							<div class="card-middle px-4 py-5">
								<div class="crdapproval-groups">

									<div class="crdapproval-single d-flex align-items-center justify-content-start mb-4">
										<div class="crdapproval-item">
											<div class="square--50 circle bg-light-success text-success"><i
													class="fa-solid fa-envelope-circle-check fs-5"></i></div>
										</div>
										<div class="crdapproval-caps ps-2">
											<p class="fw-semibold text-dark lh-2 mb-0">Verified Email</p>
											<p class="text-md text-muted lh-1 mb-0">10 Aug 2022</p>
										</div>
									</div>

									<div class="crdapproval-single d-flex align-items-center justify-content-start mb-4">
										<div class="crdapproval-item">
											<div class="square--50 circle bg-light-success text-success"><i
													class="fa-solid fa-phone-volume fs-5"></i></div>
										</div>
										<div class="crdapproval-caps ps-2">
											<p class="fw-semibold text-dark lh-2 mb-0">Verified Mobile Number</p>
											<p class="text-md text-muted lh-1 mb-0">12 Aug 2022</p>
										</div>
									</div>

									<div class="crdapproval-single d-flex align-items-center justify-content-start">
										<div class="crdapproval-item">
											<div class="square--50 circle bg-light-warning text-warning"><i
													class="fa-solid fa-file-invoice fs-5"></i></div>
										</div>
										<div class="crdapproval-caps ps-2">
											<p class="fw-semibold text-dark lh-2 mb-0">Complete Basic Info</p>
											<p class="text-md text-muted lh-1 mb-0">Not Verified</p>
										</div>
									</div>

								</div>
							</div>

							<div class="card-middle mt-5 mb-4 px-4">
								<div class="revs-wraps mb-3">
									<div class="revs-wraps-flex d-flex align-items-center justify-content-between mb-1">
										<span class="text-dark fw-semibold text-md">Complete Your Profile</span>
										<span class="text-dark fw-semibold text-md">75%</span>
									</div>
									<div class="progress " role="progressbar" aria-label="Example" aria-valuenow="87" aria-valuemin="0"
										aria-valuemax="100" style="height: 7px">
										<div class="progress-bar bg-success" style="width: 87%"></div>
									</div>
								</div>
								<div class="crd-upgrades">
									<button class="btn btn-light-primary fw-medium full-width rounded-2" type="button"><i
											class="fa-solid fa-sun me-2"></i>Upgrade Pro</button>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-8 col-lg-8 col-md-12">

						<!-- Personal Information -->
						<div class="card">
							<div class="card-header">
								<h4><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</h4>
							</div>
							<div class="card-body">

								<div class="card list-layout-block border rounded-3 p-3 mb-4">
									<div class="row">

										<div class="col-xl-4 col-lg-3 col-md">
											<div class="cardImage__caps rounded-2 overflow-hidden h-100">
												<img class="img-fluid h-100 object-fit" src="assets/img/hotel/hotel-1.jpg" alt="image">
											</div>
										</div>

										<div class="col-xl col-lg col-md">
											<div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
												<div class="d-flex align-items-center justify-content-start">
													<div class="d-inline-block">
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
													</div>
												</div>
												<h4 class="fs-5 fw-bold mb-1">Hotel Chancellor@Orchard</h4>
												<ul class="row g-2 p-0">
													<li class="col-auto">
														<p class="text-muted-2 text-md">Waterloo and Southwark</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md">9.8 km from Delhi Airport</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md"><a href="#" class="text-primary">Show on Map</a></p>
													</li>
												</ul>
												<div class="detail ellipsis-container mt-3">
													<span class="ellipsis">Parking</span>
													<span class="ellipsis">WiFi</span>
													<span class="ellipsis">Eating</span>
													<span class="ellipsis">Cooling</span>
													<span class="ellipsis">Pet</span>
												</div>
												<div class="position-relative mt-3">
													<div class="fw-medium text-dark">Standard Twin Double Room</div>
													<div class="text-md text-muted">Last booed 25min ago</div>
												</div>
												<div class="position-relative mt-4">
													<div class="d-block position-relative"><span class="label bg-light-success text-success">Free
															Cancellation, till 1 hour of Pick up</span></div>
													<div class="text-md">
														<p class="m-0">Room type: Standard King Room <a class="text-primary">Change Room</a></p>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

								<div class="card list-layout-block border rounded-3 p-3 mb-4">
									<div class="row">

										<div class="col-xl-4 col-lg-3 col-md">
											<div class="cardImage__caps rounded-2 overflow-hidden h-100">
												<img class="img-fluid h-100 object-fit" src="assets/img/hotel/hotel-1.jpg" alt="image">
											</div>
										</div>

										<div class="col-xl col-lg col-md">
											<div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
												<div class="d-flex align-items-center justify-content-start">
													<div class="d-inline-block">
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
													</div>
												</div>
												<h4 class="fs-5 fw-bold mb-1">Hotel Chancellor@Orchard</h4>
												<ul class="row g-2 p-0">
													<li class="col-auto">
														<p class="text-muted-2 text-md">Waterloo and Southwark</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md">9.8 km from Delhi Airport</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md"><a href="#" class="text-primary">Show on Map</a></p>
													</li>
												</ul>
												<div class="detail ellipsis-container mt-3">
													<span class="ellipsis">Parking</span>
													<span class="ellipsis">WiFi</span>
													<span class="ellipsis">Eating</span>
													<span class="ellipsis">Cooling</span>
													<span class="ellipsis">Pet</span>
												</div>
												<div class="position-relative mt-3">
													<div class="fw-medium text-dark">Standard Twin Double Room</div>
													<div class="text-md text-muted">Last booed 25min ago</div>
												</div>
												<div class="position-relative mt-4">
													<div class="d-block position-relative"><span class="label bg-light-success text-success">Free
															Cancellation, till 1 hour of Pick up</span></div>
													<div class="text-md">
														<p class="m-0">Room type: Standard King Room <a class="text-primary">Change Room</a></p>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

								<div class="card list-layout-block border rounded-3 p-3 mb-4">
									<div class="row">

										<div class="col-xl-4 col-lg-3 col-md">
											<div class="cardImage__caps rounded-2 overflow-hidden h-100">
												<img class="img-fluid h-100 object-fit" src="assets/img/hotel/hotel-1.jpg" alt="image">
											</div>
										</div>

										<div class="col-xl col-lg col-md">
											<div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
												<div class="d-flex align-items-center justify-content-start">
													<div class="d-inline-block">
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
													</div>
												</div>
												<h4 class="fs-5 fw-bold mb-1">Hotel Chancellor@Orchard</h4>
												<ul class="row g-2 p-0">
													<li class="col-auto">
														<p class="text-muted-2 text-md">Waterloo and Southwark</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md">9.8 km from Delhi Airport</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md"><a href="#" class="text-primary">Show on Map</a></p>
													</li>
												</ul>
												<div class="detail ellipsis-container mt-3">
													<span class="ellipsis">Parking</span>
													<span class="ellipsis">WiFi</span>
													<span class="ellipsis">Eating</span>
													<span class="ellipsis">Cooling</span>
													<span class="ellipsis">Pet</span>
												</div>
												<div class="position-relative mt-3">
													<div class="fw-medium text-dark">Standard Twin Double Room</div>
													<div class="text-md text-muted">Last booed 25min ago</div>
												</div>
												<div class="position-relative mt-4">
													<div class="d-block position-relative"><span class="label bg-light-success text-success">Free
															Cancellation, till 1 hour of Pick up</span></div>
													<div class="text-md">
														<p class="m-0">Room type: Standard King Room <a class="text-primary">Change Room</a></p>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

								<div class="card list-layout-block border rounded-3 p-3">
									<div class="row">

										<div class="col-xl-4 col-lg-3 col-md">
											<div class="cardImage__caps rounded-2 overflow-hidden h-100">
												<img class="img-fluid h-100 object-fit" src="assets/img/hotel/hotel-1.jpg" alt="image">
											</div>
										</div>

										<div class="col-xl col-lg col-md">
											<div class="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
												<div class="d-flex align-items-center justify-content-start">
													<div class="d-inline-block">
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
														<i class="fa fa-star text-warning text-xs"></i>
													</div>
												</div>
												<h4 class="fs-5 fw-bold mb-1">Hotel Chancellor@Orchard</h4>
												<ul class="row g-2 p-0">
													<li class="col-auto">
														<p class="text-muted-2 text-md">Waterloo and Southwark</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md">9.8 km from Delhi Airport</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md fw-bold">.</p>
													</li>
													<li class="col-auto">
														<p class="text-muted-2 text-md"><a href="#" class="text-primary">Show on Map</a></p>
													</li>
												</ul>
												<div class="detail ellipsis-container mt-3">
													<span class="ellipsis">Parking</span>
													<span class="ellipsis">WiFi</span>
													<span class="ellipsis">Eating</span>
													<span class="ellipsis">Cooling</span>
													<span class="ellipsis">Pet</span>
												</div>
												<div class="position-relative mt-3">
													<div class="fw-medium text-dark">Standard Twin Double Room</div>
													<div class="text-md text-muted">Last booed 25min ago</div>
												</div>
												<div class="position-relative mt-4">
													<div class="d-block position-relative"><span class="label bg-light-success text-success">Free
															Cancellation, till 1 hour of Pick up</span></div>
													<div class="text-md">
														<p class="m-0">Room type: Standard King Room <a class="text-primary">Change Room</a></p>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>

					</div>

				</div>
			</div>
		</section>
</template>

<script>
    export default{
        name: 'WishSection'
    }
</script>