<template>
    <!-- ============================ User Dashboard Menu ============================ -->
		<div class="dashboard-menus border-top d-none d-lg-block">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 col-lg-12 col-md-12">
						<ul class="user-Dashboard-menu">
							<li class="active"><router-link to="/profile"><i class="fa-regular fa-id-card me-2"></i>My Profile</router-link></li>
							<li><router-link to="/bookings"><i class="fa-solid fa-ticket me-2"></i>Trip Planner</router-link></li>
							<li><router-link to="/upcoming_plan"><i class="fa-solid fa-sliders me-2"></i>Upcoming Trip</router-link></li>
							<li><router-link to=""><i class="fa-solid fa-wallet me-2"></i>Payment Details</router-link></li>
							<li><router-link to="/wish_list"><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</router-link></li>
							<li><router-link to=""><i class="fa-solid fa-power-off me-2"></i>Sign Out</router-link></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	<!-- ============================ End user Dashboard Menu ============================ -->
</template>

<script>
    export default{
        name: 'AuthenticatedHeader',
    }
</script>
