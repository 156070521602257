<template>
    <ProfilePage/>
</template>

<script>
import ProfilePage from '@/components/profile/ProfilePage';
    export default{
        name: 'ProfileView',
        components: {
            ProfilePage,
        }
    }
</script>