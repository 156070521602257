<template>
    <div id="main-wrapper">
        <HeaderComponentPublic/>
        <BannerSection/>
        <FeaturesSection/>
        <ServicesList/>
        <FooterSection/>
    </div>
</template>

<script>

import HeaderComponentPublic from '@/components/navigation/HeaderComponentPublic'
import BannerSection from '@/components/home/BannerSection';
import FeaturesSection from '@/components/home/FeaturesSection';
import ServicesList from '@/components/home/ServicesList';
import FooterSection from '@/components/footer/FooterSection';
    export default{
        name: 'HomePage',
        components:{
            HeaderComponentPublic,
            
            BannerSection,
            FeaturesSection,
            FooterSection,
            ServicesList,
        }
    }
</script>