<template>
	<div class="card mb-4">
	<div class="card-header">
		<h4><i class="fa-regular fa-bell me-2"></i>Select Areas of Interest</h4>
	</div>
	<div class="card-body">
		<div class="eportledd mb-4">
			<h6>Heritage & Culture<span class="text-danger">*</span></h6>
			<div class="row">
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Museum
					</label>
				</div>
				</div>
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Hiking
					</label>
				</div>
				</div>
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Sport
					</label>
				</div>
				</div>
			</div>
		</div>
		<div class="eportledd mb-4">
			<h6>Nature and Activities<span class="text-danger">*</span></h6>
			<div class="row">
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Museum
					</label>
				</div>
				</div>
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Hiking
					</label>
				</div>
				</div>
				<div class="col-md-3">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="Productivity">
					<label class="form-check-label" for="Productivity">
					Sport
					</label>
				</div>
				</div>
			</div>
		</div>
		<div class="pushNotify d-flex align-items-start justify-content-between mb-3">
			<div class="pushNotify-flex">
				<p class="lh-2 fw-semibold text-dark mb-0">Shopping & Markets</p>
				<span class="text-md text-muted-2">Recommend Markets by area and interests</span>
			</div>
			<div class="pushNotify-endflex">
				<div class="form-check form-switch">
				<input class="form-check-input" type="checkbox" role="switch" id="mobilepush" checked>
				<label class="form-check-label" for="mobilepush"></label>
				</div>
			</div>
		</div>
		<div class="pushNotify d-flex align-items-start justify-content-between mb-3">
			<div class="pushNotify-flex">
				<p class="lh-2 fw-semibold text-dark mb-0">Entertainment</p>
				<span class="text-md text-muted-2">Recommend by interests, location and budget</span>
			</div>
			<div class="pushNotify-endflex">
				<div class="form-check form-switch">
				<input class="form-check-input" type="checkbox" role="switch" id="desktoppush" checked>
				<label class="form-check-label" for="desktoppush"></label>
				</div>
			</div>
		</div>
		<div class="d-sm-flex justify-content-start">
			<button type="button" class="btn btn-md btn-primary fw-medium me-2 mb-0">Save changes</button>
			<a href="#" class="btn btn-md btn-dark fw-medium mb-0">Cancel</a>
		</div>
	</div>
	</div>
	<!-- Personal Information -->
	<div class="card mb-4">
	<div class="card-header">
		<h4><i class="fa-solid fa-gear me-2"></i>Onboard Settings</h4>
	</div>
	<div class="card-body">
		<form class="mb-0">
			<h6>Mobile Contact</h6>
			<label class="form-label">Add a phone number</label>
			<input type="text" class="form-control mb-2" placeholder="enter your mobile number">
			<button class="btn btn-md btn-primary fw-medium">Save</button>
		</form>
	</div>
	</div>				
</template>

<script>
    export default{
        name: 'InterestsSection',

    }
</script>