<template>
    <section class="pt-5 gray-simple position-relative">
			<div class="container">

				<div class="row align-items-center justify-content-center">
					<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
						<button class="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i
								class="fa-solid fa-gauge me-2"></i>Dashboard
							Navigation</button>
						<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
							id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
							<div class="offcanvas-header gray-simple">
								<h5 class="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
								<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
							</div>
							<div class="offcanvas-body p-0">
								<ul class="user-Dashboard-longmenu">
									<li><a href="my-profile.html"><i class="fa-regular fa-id-card me-2"></i>My Profile</a></li>
									<li class="active"><a href="my-booking.html"><i class="fa-solid fa-ticket me-2"></i>My Booking</a>
									</li>
									<li><a href="travelers.html"><i class="fa-solid fa-user-group me-2"></i>Travelers</a></li>
									<li><a href="payment-detail.html"><i class="fa-solid fa-wallet me-2"></i>Payment Details</a></li>
									<li><a href="my-wishlists.html"><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</a></li>
									<li><a href="settings.html"><i class="fa-solid fa-sliders me-2"></i>Settings</a></li>
									<li><a href="delete-account.html"><i class="fa-solid fa-trash-can me-2"></i>Delete Profile</a></li>
									<li><a href="login.html"><i class="fa-solid fa-power-off me-2"></i>Sign Out</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="row align-items-start justify-content-between gx-xl-4">

					<div class="col-xl-4 col-lg-4 col-md-12">
						<TripsCreated/>
					</div>

					<div class="col-xl-8 col-lg-8 col-md-12">

						<!-- Personal Information -->
						<div class="card">
							<div class="card-header">
								<h4><i class="fa-solid fa-ticket me-2"></i>My Bookings</h4>
								<a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#createTripModal"><span class="fw-medium">Create Trip</span></a>
								<CreateTripModal/>
							</div>

							<div class="tab-content pt-2" id="pills-tabContent">
								<div class="tab-pane fade show active" id="pills-flsfirst" role="tabpanel" aria-labelledby="pills-flsfirst-tab" tabindex="0">
									<div class="card-body">
										<div class="row align-items-center justify-content-start">
											<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
												<ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
													<li class="col-md-3 col-6">
														<input type="checkbox" class="btn-check" id="allbkk" checked>
														<label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="allbkk">All
															Booking (24)</label>
													</li>
													<li class="col-md-3 col-6">
														<input type="checkbox" class="btn-check" id="processing">
														<label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
															for="processing">Processing (02)</label>
													</li>
													<li class="col-md-3 col-6">
														<input type="checkbox" class="btn-check" id="cancelled">
														<label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
															for="cancelled">Cancelled (04)</label>
													</li>
													<li class="col-md-3 col-6">
														<input type="checkbox" class="btn-check" id="completed">
														<label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
															for="completed">Completed (10)</label>
													</li>
												</ul>
											</div>
										</div>

										<div class="row align-items-center justify-content-start">
											<div class="col-xl-12 col-lg-12 col-md-12">

												<!-- Single Item -->
												<div class="card border br-dashed mb-4">
													<!-- Card header -->
													<div class="card-header nds-block border-bottom flex-column flex-md-row justify-content-between align-items-center">
														<!-- Icon and Title -->
														<div class="d-flex align-items-center">
															<div class="square--50 circle bg-light-purple text-purple flex-shrink-0"><i
																	class="fa-solid fa-plane"></i></div>
															<!-- Title -->
															<div class="ms-2">
																<h6 class="card-title text-dark fs-5 mb-1">England, London</h6>
																<ul class="nav nav-divider small">
																	<li class="nav-item text-muted">Booking ID: BKR24530</li>
																	<li class="nav-item ms-2"><span class="label bg-light-success text-success">Business
																			class</span></li>
																</ul>
															</div>
														</div>

														<!-- Button -->
														<div class="mt-2 mt-md-0">
															<a href="#" style="margin-right:10px" data-bs-toggle="modal" data-bs-target="#createPaymentModal" class="btn btn-sm btn-primary fw-medium mb-0">Pay Service</a>
															<a href="#" class="btn btn-sm btn-light-seegreen fw-medium mb-0">Manage Booking</a>
														</div>
													</div>

													<!-- Card body -->
													<div class="card-body">
														<div class="row g-3">
															<div class="col-sm-6 col-md-4">
																<span>Departure time</span>
																<h6 class="mb-0">Fri 12 Aug 14:00 PM</h6>
															</div>

															<div class="col-sm-6 col-md-4">
																<span>Arrival time</span>
																<h6 class="mb-0">Fri 12 Aug 18:00 PM</h6>
															</div>

															<div class="col-md-4">
																<span>Booked by</span>
																<h6 class="mb-0">Kudzai Frank</h6>
															</div>
														</div>
													</div>
												</div>
												<hr>

												<!-- Single Item -->
												<div class="card border br-dashed mb-4">
													<!-- Card header -->
													<div class="card-header nds-block border-bottom flex-column flex-md-row justify-content-between align-items-center">
														<!-- Icon and Title -->
														<div class="d-flex align-items-center">
															<div class="ms-2">
																<h6 class="card-title text-dark fs-5 mb-1">Book Services and Products for your Trip</h6>
															</div>
														</div>
													</div>

													<!-- Card body -->
													<div class="card-body">
														<div class="row g-3">
															<div class="col-sm-6 col-md-4">
																<span>Check-In</span>
																<h6 class="mb-0">Tue 10 Sep 10:00 AM</h6>
															</div>

															<div class="col-sm-6 col-md-4">
																<span>Check-Out</span>
																<h6 class="mb-0">Tue 14 Sep 18:00 PM</h6>
															</div>

															<div class="col-md-4">
																<span>Total Guest</span>
																<h6 class="mb-0">3 Adult . 2 Child</h6>
															</div>
														</div>
													</div>
												</div>

												<!-- Single Item -->
												<div class="card border br-dashed mb-4">
													<!-- Card header -->
													<div class="card-header nds-block border-bottom flex-column flex-md-row justify-content-between align-items-center">
														<!-- Icon and Title -->
														<div class="d-flex align-items-center">
															<div class="square--50 circle bg-light-success text-success flex-shrink-0"><i
																	class="fa-solid fa-thumbs-up"></i></div>
															<!-- Title -->
															<div class="ms-2">
																<h6 class="card-title text-dark fs-5 mb-1">Sky Diving</h6>
																<ul class="nav nav-divider small">
																	<li class="nav-item text-muted">Location: London Manchester</li>
																	<li class="nav-item ms-2"><span class="text-dark fw-medium">Age Restriction: 18 yrs</span></li>
																</ul>
															</div>
														</div>

														<!-- Button -->
														<div class="mt-2 mt-md-0">
															<a href="#" style="margin-right:10px" data-bs-toggle="modal" data-bs-target="#createPaymentModal" class="btn btn-sm btn-primary fw-medium mb-0">Pay Service</a>
															<a href="#" class="btn btn-sm btn-light-seegreen fw-medium mb-0">Manage Booking</a>
														</div>
													</div>

													<!-- Card body -->
													<div class="card-body">
														<div class="row g-3">
															<div class="col-sm-6 col-md-4">
																<span>Pickup address</span>
																<h6 class="mb-0">220K.V Jail Road</h6>
															</div>

															<div class="col-sm-6 col-md-4">
																<span>Booked For</span>
																<h6 class="mb-0">29 April</h6>
															</div>

															<div class="col-md-4">
																<span>Status</span>
																<h6 class="mb-0">Paid</h6>
															</div>
														</div>
													</div>
												</div>

												<!-- Single Item -->
												<div class="card border br-dashed mb-4">
													<!-- Card header -->
													<div class="card-header nds-block border-bottom flex-column flex-md-row justify-content-between align-items-center">
														<!-- Icon and Title -->
														<div class="d-flex align-items-center">
															<div class="square--50 circle bg-light-purple text-purple flex-shrink-0"><i
																	class="fa-solid fa-thumbs-down"></i></div>
															<!-- Title -->
															<div class="ms-2">
																<h6 class="card-title text-dark fs-5 mb-1">Hotel<label
																		class="badge text-danger bg-light-danger fw-medium text-md ms-2">Cancelled</label></h6>
																<ul class="nav nav-divider small">
																	<li class="nav-item text-muted">Booking ID: BKR24530</li>
																	<li class="nav-item ms-2"><span class="label bg-light-success text-success">Bristol Hotel</span></li>
																</ul>
															</div>
														</div>

														<!-- Button -->
														<div class="mt-2 mt-md-0">
															<a href="#" style="margin-right:10px" data-bs-toggle="modal" data-bs-target="#createPaymentModal" class="btn btn-sm btn-primary fw-medium mb-0">Pay Service</a>
															<a href="#" class="btn btn-sm btn-light-seegreen fw-medium mb-0">ReBooking</a>
														</div>
													</div>

													<!-- Card body -->
													<div class="card-body">
														<div class="row g-3">
															<div class="col-sm-6 col-md-4">
																<span>Stay Duration</span>
																<h6 class="mb-0">10 Days</h6>
															</div>

															<div class="col-sm-6 col-md-4">
																<span>Number of People</span>
																<h6 class="mb-0">5</h6>
															</div>

															<div class="col-md-4">
																<span>Status</span>
																<h6 class="mb-0">Suspended</h6>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>

								<div class="tab-pane fade show" id="pills-flssecond" role="tabpanel" aria-labelledby="pills-flssecond-tab" tabindex="0">
									<div class="card-body">
										<div class="row align-items-center justify-content-start">
											<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
												<ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
													<li class="col-md-3 col-6">
														<input type="checkbox" class="btn-check" id="allbkk" checked>
														<label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="allbkk">No Bookings Available for this trip</label>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>

					</div>

				</div>
			</div>
			<CreatePaymentModal/>
		</section>
</template>

<script>
	import CreateTripModal from '@/components/trip_planner/CreateTripModal';
	import TripsCreated from '@/components/trip_planner/TripsCreated';
	import CreatePaymentModal from '@/components/payments/CreatePayment';
    export default{
        name: 'BookingsSection',
		components:{
			CreateTripModal,
			TripsCreated,
			CreatePaymentModal
		}
    }
</script>