<template>
    <div class="image-cover hero-header bg-white" style="background:url(assets/img/banner.jpg)no-repeat;"
      data-overlay="5">
      <div class="container">

        <!-- Search Form -->
        <div class="row justify-content-center align-items-center">
          <div class="col-xl-9 col-lg-10 col-md-12 col-sm-12">
            <div class="position-relative text-center mb-5">
              <h1>Starts Your Trip with <span class="position-relative z-4">Tsamaye<span
                    class="position-absolute top-50 start-50 translate-middle d-none d-md-block mt-4">
                    <svg width="185px" height="23px" viewBox="0 0 445.5 23">
                      <path class="fill-white opacity-7"
                        d="M409.9,2.6c-9.7-0.6-19.5-1-29.2-1.5c-3.2-0.2-6.4-0.2-9.7-0.3c-7-0.2-14-0.4-20.9-0.5 c-3.9-0.1-7.8-0.2-11.7-0.3c-1.1,0-2.3,0-3.4,0c-2.5,0-5.1,0-7.6,0c-11.5,0-23,0-34.5,0c-2.7,0-5.5,0.1-8.2,0.1 c-6.8,0.1-13.6,0.2-20.3,0.3c-7.7,0.1-15.3,0.1-23,0.3c-12.4,0.3-24.8,0.6-37.1,0.9c-7.2,0.2-14.3,0.3-21.5,0.6 c-12.3,0.5-24.7,1-37,1.5c-6.7,0.3-13.5,0.5-20.2,0.9C112.7,5.3,99.9,6,87.1,6.7C80.3,7.1,73.5,7.4,66.7,8 C54,9.1,41.3,10.1,28.5,11.2c-2.7,0.2-5.5,0.5-8.2,0.7c-5.5,0.5-11,1.2-16.4,1.8c-0.3,0-0.7,0.1-1,0.1c-0.7,0.2-1.2,0.5-1.7,1 C0.4,15.6,0,16.6,0,17.6c0,1,0.4,2,1.1,2.7c0.7,0.7,1.8,1.2,2.7,1.1c6.6-0.7,13.2-1.5,19.8-2.1c6.1-0.5,12.3-1,18.4-1.6 c6.7-0.6,13.4-1.1,20.1-1.7c2.7-0.2,5.4-0.5,8.1-0.7c10.4-0.6,20.9-1.1,31.3-1.7c6.5-0.4,13-0.7,19.5-1.1c2.7-0.1,5.4-0.3,8.1-0.4 c10.3-0.4,20.7-0.8,31-1.2c6.3-0.2,12.5-0.5,18.8-0.7c2.1-0.1,4.2-0.2,6.3-0.2c11.2-0.3,22.3-0.5,33.5-0.8 c6.2-0.1,12.5-0.3,18.7-0.4c2.2-0.1,4.4-0.1,6.7-0.1c11.5-0.1,23-0.2,34.6-0.4c7.2-0.1,14.4-0.1,21.6-0.1c12.2,0,24.5,0.1,36.7,0.1 c2.4,0,4.8,0.1,7.2,0.2c6.8,0.2,13.5,0.4,20.3,0.6c5.1,0.2,10.1,0.3,15.2,0.4c3.6,0.1,7.2,0.4,10.8,0.6c10.6,0.6,21.1,1.2,31.7,1.8 c2.7,0.2,5.4,0.4,8,0.6c2.9,0.2,5.8,0.4,8.6,0.7c0.4,0.1,0.9,0.2,1.3,0.3c1.1,0.2,2.2,0.2,3.2-0.4c0.9-0.5,1.6-1.5,1.9-2.5 c0.6-2.2-0.7-4.5-2.9-5.2c-1.9-0.5-3.9-0.7-5.9-0.9c-1.4-0.1-2.7-0.3-4.1-0.4c-2.6-0.3-5.2-0.4-7.9-0.6 C419.7,3.1,414.8,2.9,409.9,2.6z">
                      </path>
                    </svg>
                  </span></span></h1>
              <p class="fs-5 fw-light">Take a little break from the work stress of everyday. Discover plan trip and
                explore beautiful destinations.</p>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="search-wrap bg-white rounded-3 p-3">
              <div class="search-upper">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="flx-start mb-sm-0 mb-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="trip" id="return" value="option1" checked>
                      <label class="form-check-label" for="return">Return</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="trip" id="oneway" value="option2">
                      <label class="form-check-label" for="oneway">One Way</label>
                    </div>
                  </div>
                  <div class="flx-end d-flex align-items-center flex-wrap">
                    <div class="px-sm-2 pb-3 pt-0 ps-0 mob-full">
                      <div class="booking-form__input guests-input">
                        <i class="fa-solid fa-user-clock text-muted me-2"></i><button name="guests-btn"
                          id="guests-input-btn">1
                          Guest</button>
                        <div class="guests-input__options" id="guests-input-options">
                          <div>
                            <span class="guests-input__ctrl minus" id="adults-subs-btn"><i
                                class="fa-solid fa-minus"></i></span>
                            <span class="guests-input__value"><span id="guests-count-adults">1</span>Adults</span>
                            <span class="guests-input__ctrl plus" id="adults-add-btn"><i
                                class="fa-solid fa-plus"></i></span>
                          </div>
                          <div>
                            <span class="guests-input__ctrl minus" id="children-subs-btn"><i
                                class="fa-solid fa-minus"></i></span>
                            <span class="guests-input__value"><span id="guests-count-children">0</span>Children</span>
                            <span class="guests-input__ctrl plus" id="children-add-btn"><i
                                class="fa-solid fa-plus"></i></span>
                          </div>
                          <div>
                            <span class="guests-input__ctrl minus" id="room-subs-btn"><i
                                class="fa-solid fa-minus"></i></span>
                            <span class="guests-input__value"><span id="guests-count-room">0</span>Rooms</span>
                            <span class="guests-input__ctrl plus" id="room-add-btn"><i
                                class="fa-solid fa-plus"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ps-1 pb-3 pt-0 mob-full">
                      <div class="dropdowns">
                        <div class="selections">
                          <i class="fa-solid fa-basket-shopping text-muted me-2"></i><span
                            class="selected">Economy</span>
                          <div class="caret"></div>
                        </div>
                        <ul class="menu">
                          <li class="active">Economy</li>
                          <li>Premium Economy</li>
                          <li>Premium Economy</li>
                          <li>Business/First</li>
                          <li>Business</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gx-lg-2 g-3">

                <div class="col-xl-6 col-lg-6 col-md-12">
                  <div class="row gy-3 gx-lg-2 gx-3">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                      <div class="form-group hdd-arrow mb-0">
                        <select class="leaving form-control fw-bold">
                          <option value="">Select</option>
                          <option value="ldn">London</option>
                          <option value="mcr">Manchester</option>
                          <option value="edn">Edinburgh</option>
                          <option value="brm">Birmingham</option>
                          <option value="lvp">Liverpool</option>
                          <option value="glw">Glasgow</option>
                          <option value="brt">Bristol</option>
                          <option value="oxf">Oxford</option>
                        </select>
                      </div>
                      <div class="btn-flip-icon mt-md-0">
                        <button class="p-0 m-0 text-primary"><i class="fa-solid fa-right-left"></i></button>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="form-groupp hdd-arrow mb-0">
                        <select class="goingto form-control fw-bold">
                          <option value="">Select</option>
                          <option value="lv">Las Vegas</option>
                          <option value="la">Los Angeles</option>
                          <option value="kc">Kansas City</option>
                          <option value="no">New Orleans</option>
                          <option value="kc">Jacksonville</option>
                          <option value="lb">Long Beach</option>
                          <option value="cl">Columbus</option>
                          <option value="cn">Canada</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="row gy-3 gx-lg-2 gx-3">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group mb-0">
                        <input class="form-control fw-bold choosedate" type="text" placeholder="Departure.."
                          readonly="readonly">
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                      <div class="form-group mb-0">
                        <input class="form-control fw-bold choosedate" type="text" placeholder="Return.."
                          readonly="readonly">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-12">
                  <div class="form-group mb-0">
                    <button type="button" class="btn btn-primary full-width fw-medium"><i
                        class="fa-solid fa-magnifying-glass me-2"></i>Search</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- </row> -->

      </div>
    </div>
</template>

<script>
    export default{
        name: 'BannerSection',
    }
</script>