<template>
    <section class="pt-5 gray-simple position-relative">
			<div class="container">

				<div class="row align-items-center justify-content-center">
					<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
						<button class="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i
								class="fa-solid fa-gauge me-2"></i>Dashboard
							Navigation</button>
						<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
							id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
							<div class="offcanvas-header gray-simple">
								<h5 class="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
								<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
							</div>
							<div class="offcanvas-body p-0">
								<ul class="user-Dashboard-longmenu">
									<li class="active"><a href="my-profile.html"><i class="fa-regular fa-id-card me-2"></i>My Profile</a>
									</li>
									<li><a href="my-booking.html"><i class="fa-solid fa-ticket me-2"></i>My Booking</a>
									</li>
									<li><a href="travelers.html"><i class="fa-solid fa-user-group me-2"></i>Travelers</a></li>
									<li><a href="payment-detail.html"><i class="fa-solid fa-wallet me-2"></i>Payment Details</a></li>
									<li><a href="my-wishlists.html"><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</a></li>
									<li><a href="settings.html"><i class="fa-solid fa-sliders me-2"></i>Settings</a></li>
									<li><a href="delete-account.html"><i class="fa-solid fa-trash-can me-2"></i>Delete Profile</a></li>
									<li><a href="login.html"><i class="fa-solid fa-power-off me-2"></i>Sign Out</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="row align-items-start justify-content-between gx-xl-4">

					<div class="col-xl-8 col-lg-8 col-md-12">
						<div class="card rounded-2 me-xl-5 mb-4">
							<InterestsSection/>
							<hr style="margin-top:2%; margin-bottom:2%">
							<BillingHistory style="margin-top:4%"/>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-12">

						<!-- Personal Information -->
						<div class="card mb-4">
							<div class="card-header">
								<h4><i class="fa-solid fa-file-invoice me-2"></i>Personal Information</h4>
							</div>
							<div class="card-body">
								
								<div class="row align-items-center justify-content-start">

									<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
										<div class="d-flex align-items-center">
											<label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
												<!-- Avatar place holder -->
												<span class="avatar avatar-xl">
													<img id="uploadfile-1-preview"
														class="avatar-img rounded-circle border border-white border-3 shadow" src="assets/img/team-1.jpg"
														alt="">
												</span>
											</label>
											<!-- Upload button -->
											<label class="btn btn-sm btn-light-primary px-4 fw-medium mb-0" for="uploadfile-1">Change</label>
											<input id="uploadfile-1" class="form-control d-none" type="file">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">First Name</label>
											<input type="text" class="form-control" value="Adam K">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Last Name</label>
											<input type="text" class="form-control" value="Divliars">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Email ID</label>
											<input type="text" class="form-control" value="adamkruck@gmail.com">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Mobile</label>
											<input type="text" class="form-control" value="9856542563">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Date of Birth</label>
											<input type="date" class="form-control" value="02/04/2000">
										</div>
									</div>

									<div class="col-xl-6 col-lg-6 col-md-6">
										<div class="form-group">
											<label class="form-label">Gender</label>
											<input type="text" class="form-control" value="Male">
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">About Info</label>
											<textarea
												class="form-control ht-120">Lorem ipsum dolor sit amet, nec virtute nusquam ex. Ex sed diceret constituam inciderint, accusamus imperdiet has te. Id qui liber nemore semper, modus appareat philosophia ut eam. Assum tibique singulis at mel.</textarea>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="card mb-4">
							<div class="card-header">
								<h4><i class="fa-solid fa-envelope-circle-check me-2"></i>Update Your Email</h4>
							</div>
							<div class="card-body">
								<div class="row align-items-center justify-content-start">

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">Email Address</label>
											<input type="email" class="form-control" placeholder="update your new email">
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="text-end">
											<a href="#" class="btn btn-md btn-primary mb-0">Update Email</a>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="card">
							<div class="card-header">
								<h4><i class="fa-solid fa-lock me-2"></i>Update Password</h4>
							</div>
							<div class="card-body">
								<div class="row align-items-center justify-content-start">

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">Old Password</label>
											<input type="password" class="form-control" placeholder="*********">
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">New Password</label>
											<input type="password" class="form-control" placeholder="*********">
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="form-label">Confirm Password</label>
											<input type="password" class="form-control" placeholder="*********">
										</div>
									</div>

									<div class="col-xl-12 col-lg-12 col-md-12">
										<div class="text-end">
											<a href="#" class="btn btn-md btn-primary mb-0">Change Password</a>
										</div>
									</div>

								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</section>
</template>

<script>
import BillingHistory from '@/components/payments/BillingsHistory';
import InterestsSection from '@/components/interests/InterestsSection';
    export default{
        name: 'UserProfile',
		components:{
			BillingHistory,
			InterestsSection,
		}
    }
</script>