<template>
    <div id="main-wrapper">
        <HeaderComponent/>
        <AuthenticatedHeader/>
        <BookingsSection/>
        <FooterSection/>
    </div>
</template>

<script>
import HeaderComponent from '@/components/navigation/HeaderComponent';
import AuthenticatedHeader from '@/components/navigation/AuthenticatedHeader';
import BookingsSection from '@/components/bookings/BookingsSection';
import FooterSection from '@/components/footer/FooterSection';
    export default{
        name: 'BookingsPage',
        components:{
            HeaderComponent,
            AuthenticatedHeader,
            BookingsSection,
            FooterSection,
        }
    }
</script>