<template>
  <HomePage/>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/home/HomePage';

export default {
  name: 'HomeView',
  components: {
    HomePage
  }
}
</script>
