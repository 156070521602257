<template>
  <AboutPage/>
</template>

<script>
// @ is an alias to /src
import AboutPage from '@/components/about/AboutPage';

export default {
  name: 'AboutView',
  components: {
    AboutPage
  }
}
</script>
