<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div class="header header-light">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
				<img :src="Logo" alt="Logo" class="logo" style="width:10%;"/>
			</a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                
                <li class="languageDropdown me-2">
                  <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#countryModal"><img
                      src="assets/img/flag/flag.png" class="img-fluid" width="17" alt="Country"></a>
                </li>
                <CountriesModal/>
                <li>
                  <a href="#" class="bg-light-primary text-primary rounded" data-bs-toggle="modal"
                    data-bs-target="#login"><i class="fa-regular fa-circle-user fs-6"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper" style="transition-property: none;">
            <ul class="nav-menu">

              <li><router-link to="/">Home<span class="submenu-indicator"></span></router-link>
                
              </li>
			  
			  <li><router-link to="/about">About Us<span class="submenu-indicator"></span></router-link></li>

              <li><a href="">Places<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="">Food and Local Cuisine<span class="submenu-indicator"></span></a>
                  </li>
                  <li><a href="">History and Culture<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li><a href="">Nature and Activities<span class="submenu-indicator"></span></a>

                  </li>
                  <li><a href="">Sporting<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li><a href="">Shopping and Markets<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li>
                    <a href="">Entertainment</a>
                  </li>
                  <li>
                    <a href="">Architecture and Landmarks</a>
                  </li>
                  <li>
                    <a href="">Relaxation and Wellness</a>
                  </li>
                  
                </ul>
              </li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">
							
							<li class="languageDropdown me-2">
								
							</li>
							<li class="list-buttons">
								<a href="#" class="bg-primary" data-bs-toggle="modal" data-bs-target="#login"><i
									class="fa-regular fa-circle-user fs-6 me-2"></i>Sign In / Register</a>
							</li>
						</ul>
          </div>
        </nav>
      </div>
    </div>

	<!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
        <div class="modal-content" id="loginmodal">
          <div class="modal-header">
            <h4 class="modal-title fs-6">Sign In / Register</h4>
            <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i
                class="fa-solid fa-square-xmark"></i></a>
          </div>
          <div class="modal-body">
            <div class="modal-login-form py-4 px-md-3 px-0">
              <form>

                <div class="form-floating mb-4">
                  <input type="email" class="form-control" placeholder="name@example.com">
                  <label>User Name</label>
                </div>
                <div class="form-floating mb-4">
                  <input type="password" class="form-control" placeholder="Password">
                  <label>Password</label>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary full-width font--bold btn-lg">Sign In</button>
                </div>

                <div class="modal-flex-item d-flex align-items-center justify-content-between mb-3">
                  <div class="modal-flex-first">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="savepassword" value="option1">
                      <label class="form-check-label" for="savepassword">Save Password</label>
                    </div>
                  </div>
                  <div class="modal-flex-last">
                    <a href="JavaScript:Void(0);" class="text-primary fw-medium">Forget Password?</a>
                  </div>
                </div>
              </form>
            </div>

            <div class="prixer px-3">
              <div class="devider-wraps position-relative">
                <div class="devider-text text-muted-2 text-md">Sign In with More Methods</div>
              </div>
            </div>

            <div class="social-login py-4 px-2">
              <ul class="row align-items-center justify-content-between g-3 p-0 m-0">
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2 full-width"><i
                      class="fa-brands fa-facebook color--facebook fs-2"></i></a></li>
               
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2"><i
                      class="fa-brands fa-linkedin color--linkedin fs-2"></i></a></li>
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2"><i
                      class="fa-brands fa-google color--google fs-2"></i></a></li>
                
              </ul>
            </div>
          </div>
          <div class="modal-footer align-items-center justify-content-center">
            <p>Don't have an account yet?<a href="" data-bs-toggle="modal" data-bs-target="#register" class="text-primary fw-medium ms-1">Sign Up</a></p>
          </div>
        </div>
      </div>
    </div>


	<div class="modal fade" id="register" tabindex="-1" role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
        <div class="modal-content" id="loginmodal">
          <div class="modal-header">
            <h4 class="modal-title fs-6">Sign In / Register</h4>
            <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i
                class="fa-solid fa-square-xmark"></i></a>
          </div>
          <div class="modal-body">
            <div class="modal-login-form py-4 px-md-3 px-0">
              <form>

                <div class="form-floating mb-4">
                  <input type="text" class="form-control" placeholder="Full Name">
                  <label>Full Name</label>
                </div>

				<div class="form-floating mb-4">
                  <select type="text" class="form-control">
						<option>United Kingdom</option>
						<option>Wales</option>
				  </select>
                  <label>Country of Origin</label>
                </div>
                <div class="form-floating mb-4">
                  <input type="password" class="form-control" placeholder="Password">
                  <label>Password</label>
                </div>

				<div class="form-floating mb-4">
                  <input type="password" class="form-control" placeholder="Confirm Password">
                  <label>Confirm Password</label>
                </div>

				<div class="modal-flex-first">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="savepassword" value="option1">
                      <label class="form-check-label" for="savepassword">Agree to Terms & Conditions</label>
                    </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary full-width font--bold btn-lg">Signup</button>
                </div>

              </form>
            </div>

            <div class="prixer px-3">
              <div class="devider-wraps position-relative">
                <div class="devider-text text-muted-2 text-md">Sign Up with More Methods</div>
              </div>
            </div>

            <div class="social-login py-4 px-2">
              <ul class="row align-items-center justify-content-between g-3 p-0 m-0">
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2 full-width"><i
                      class="fa-brands fa-facebook color--facebook fs-2"></i></a></li>
               
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2"><i
                      class="fa-brands fa-linkedin color--linkedin fs-2"></i></a></li>
                <li class="col"><a href="#" class="square--60 border br-dashed rounded-2"><i
                      class="fa-brands fa-google color--google fs-2"></i></a></li>
                
              </ul>
            </div>
          </div>
          <div class="modal-footer align-items-center justify-content-center">
            <p>Don't have an account yet?<a href="" data-bs-toggle="modal" data-bs-target="#register" class="text-primary fw-medium ms-1">Sign Up</a></p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->
  
</template>

<script>
import CountriesModal from '@/components/nations/modals/CountriesModal';
import Logo from '@/assets/tsamaye.png';
    export default{
        name:'HeaderComponentPublic',
		data() {
			return {
			Logo: Logo
			};
		},

        components: {
          CountriesModal,

        }
    }
</script>