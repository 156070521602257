<template>
    <UpcomingPage/>
</template>

<script>
import UpcomingPage from '@/components/upcoming/UpcomingPage';
    export default{
        name: 'UpcomingView',
        components: {
            UpcomingPage,
        }
    }
</script>