<template>
    <div class="modal modal-lg fade" id="createTripModal" tabindex="-1" aria-labelledby="currenyModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title fs-6" id="currenyModalLabel">Create a Trip</h4>
                    <a href="#" class="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i
                        class="fa-solid fa-square-xmark"></i></a>
                </div>

                <div class="modal-body">
                    <div class="row align-items-center justify-content-start">

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Trip To</label>
                                <input type="text" class="form-control" value="Destination" placeholder="Destination">
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Trip From</label>
                                <input type="text" class="form-control" value="Origin" placeholder="Origin">
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Trip Date</label>
                                <input type="date" class="form-control">
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Stay Duration</label>
                                <input type="text" class="form-control" placeholder="6 Days">
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Trip Budget</label>
                                <input type="currency" class="form-control" placeholder="0.00">
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="Productivity">
                                <label class="form-check-label" for="Productivity">
                                    Do you want to attach interests to this trip?
                                </label>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Trip Details</label>
                                <textarea class="form-control ht-120">Write here</textarea>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'CreateTripModal',
        components:{

        }
    }
</script>