<template>
        <section class="bg-cover position-relative" style="background:url(assets/img/bg.jpg)no-repeat;" data-overlay="5">
			<div class="container">
				<div class="row align-items-center justify-content-center">
					<div class="col-xl-7 col-lg-9 col-md-12">

						<div class="fpc-capstion text-center my-4">
							<div class="fpc-captions">
								<h1 class="xl-heading text-light">
                                    Authentic experiences in Barbados for any budget.
                                </h1>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="fpc-banner"></div>
		</section>

        <section>
			<div class="container">

				<div class="row align-items-center justify-content-between g-4">

					<div class="col-xl-6 col-lg-6 col-md-6">
						<div class="">
							<h2 class="lh-base fs-1 fw-bold">Who We're & Mission?</h2>
							<p>At tsamaye, our mission is to champion local businesses, uplift communities, and economies through the power of travel.</p>
							<p>We are dedicated to connecting travellers with the heartbeat of each destination, ensuring that the richness of local culture and unique experiences takes center stage.</p>
						</div>

                        <div class="">
                            <h1 class="lh-base fs-1 fw-bold">About Tsamaye & Our Vision</h1>
							<p>Our vision is to cultivate a sustainable and inclusive travel ecosystem where every journey contributes to the well-being of local businesses and the enrichment of global perspectives. 
                            At tsamaye, we envision a world where travel becomes a transformative force for both explorers and the communities they visit.</p>
                        </div>
					</div>

					<div class="col-xl-5 col-lg-6 col-md-6">
						<div class="position-relative">
							<img src="assets/img/side-3.png" class="img-fluid" alt="">
						</div>
					</div>

				</div>

			</div>
		</section>


</template>

<script>
    export default{
        name: 'AboutSection',
    }
</script>