<template>
    <div id="main-wrapper">
        <HeaderComponentPublic/>
        <AboutSection/>
        <FooterSection/>
    </div>
</template>

<script>
import HeaderComponentPublic from '@/components/navigation/HeaderComponentPublic'
import AboutSection from '@/components/about/AboutSection';
import FooterSection from '@/components/footer/FooterSection';
    export default{
        name: 'AboutPage',
        components:{
            HeaderComponentPublic,
            AboutSection,
            FooterSection,
        }
    }
</script>