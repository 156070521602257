<template>
    <InterestsPage/>
</template>

<script>
import InterestsPage from '@/components/interests/InterestsPage';
    export default{
        name: 'InterestsView',
        components: {
            InterestsPage,
        }
    }
</script>