<template>
    <div id="main-wrapper">
        <HeaderComponent/>
        <AuthenticatedHeader/>
        <UpcomingSection/>
        <FooterSection/>
    </div>
</template>

<script>
import HeaderComponent from '@/components/navigation/HeaderComponent';
import AuthenticatedHeader from '@/components/navigation/AuthenticatedHeader'
import UpcomingSection from '@/components/upcoming/UpcomingSection';
import FooterSection from '@/components/footer/FooterSection';
    export default{
        name: 'UpcomingPage',
        components:{
            HeaderComponent,
            AuthenticatedHeader,
            UpcomingSection,
            FooterSection,
        }
    }
</script>