import { createRouter, createWebHistory } from 'vue-router'
import ProfileView from '@/views/ProfileView';
import BookingsView from '@/views/BookingsView';
import InterestsView from '@/views/InterestsView';
import UpcomingView from '@/views/UpcomingView';
import WishView from '@/views/WishView';

import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView
  },

  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },

  {
    path: '/bookings',
    name: 'bookings',
    component: BookingsView
  },

  {
    path: '/interests',
    name: 'interests',
    component: InterestsView
  },

  {
    path: '/upcoming_plan',
    name: 'upcoming_plan',
    component: UpcomingView
  },

  {
    path: '/wish_list',
    name: 'wish_list',
    component: WishView
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
