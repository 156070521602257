<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
    <div class="header header-light">
      <div class="container">
        <nav id="navigation" class="navigation navigation-landscape">
          <div class="nav-header">
            <a class="nav-brand" href="#">
				<img :src="Logo" alt="Logo" class="logo" style="width:10%;"/>
			</a>
            <div class="nav-toggle"></div>
            <div class="mobile_nav">
              <ul>
                
                <li class="languageDropdown me-2">
                  <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#countryModal"><img
                      src="assets/img/flag/flag.png" class="img-fluid" width="17" alt="Country"></a>
                </li>
                <CountriesModal/>
                <li>
                  <a href="#" class="bg-light-primary text-primary rounded" data-bs-toggle="modal"
                    data-bs-target="#login"><i class="fa-regular fa-circle-user fs-6"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-menus-wrapper" style="transition-property: none;">
            <ul class="nav-menu">

              <li><router-link to="/">Home<span class="submenu-indicator"></span></router-link>
                
              </li>
			  
			  <li><router-link to="/about">About Us<span class="submenu-indicator"></span></router-link></li>

              <li><a href="">Places<span class="submenu-indicator"></span></a>
                <ul class="nav-dropdown nav-submenu">
                  <li><a href="">Food and Local Cuisine<span class="submenu-indicator"></span></a>
                  </li>
                  <li><a href="">History and Culture<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li><a href="">Nature and Activities<span class="submenu-indicator"></span></a>

                  </li>
                  <li><a href="">Sporting<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li><a href="">Shopping and Markets<span class="submenu-indicator"></span></a>
                    
                  </li>
                  <li>
                    <a href="">Entertainment</a>
                  </li>
                  <li>
                    <a href="">Architecture and Landmarks</a>
                  </li>
                  <li>
                    <a href="">Relaxation and Wellness</a>
                  </li>
                  
                </ul>
              </li>

            </ul>

            <ul class="nav-menu nav-menu-social align-to-right">
							
							<li class="languageDropdown me-2">
								<a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#countryModal"><img
										src="assets/img/flag/flag.png" class="img-fluid" width="17" alt="Country"></a>
							</li>
							<li class="notificationsDropdown btn-group account-drop me-2">
								<a href="#" class="nav-link" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
										class="fa-regular fa-bell fs-6"></i></a>
								<div class="dropdown-menu pull-right animated flipInX">
									<div class="px-3 py-3 border-bottom d-flex align-items-center justify-content-between">
										<div class="d-inline-flex align-items-center">
											<h5 class="fs-6 fw-semibold mb-0">Hi, Dhananjay</h5><span
												class="badge bg-light-danger text-danger ms-2">4 new</span>
										</div>
										<div class="headr-right"><a href="#" class="text-md text-danger">Clear All</a></div>
									</div>
									<div class="notification-grousp px-3 py-3">
										<div class="single-notts mb-3">
											<h6 class="fw-semibold">your booking invoice send to your mail id.</h6>
											<p class="text-md text-muted mb-0 lh-1">05 hours ago</p>
										</div>
										<div class="single-notts mb-3">
											<h6 class="fw-semibold">Book your next flight for London with 20% off!</h6>
											<p class="text-md text-muted mb-0 lh-1">Valid 10 Aug 2023</p>
										</div>
										<div class="single-notts mb-3">
											<h6 class="fw-semibold">Sunshine saving are here 🌞 save 30% or more on a stay</h6>
											<p class="text-md text-muted mb-0 lh-1">05 hours ago</p>
										</div>
										<div class="single-notts mb-3">
											<h6 class="fw-semibold">make your shimla tour with 4 guest 50% off</h6>
											<p class="text-md text-muted mb-0 lh-1">valid 26 sep 2023</p>
										</div>
									</div>
									<div class="notts-footer text-center border-top px-3 py-3">
										<a href="#" class="text-sm fw-medium text-seegreen">See all incoming activity</a>
									</div>
								</div>
							</li>
							<li>
								<div class="btn-group account-drop">
									<button type="button" class="btn btn-order-by-filt" data-bs-toggle="dropdown" aria-haspopup="true"
										aria-expanded="false">
										<img src="assets/img/team-5.jpg" class="img-fluid" alt="">
									</button>
									<div class="dropdown-menu pull-right animated flipInX">
										<div class="drp_menu_headr">
											<h4>Hi, Dhananjay</h4>
											<div class="drp_menu_headr-right"><button type="button"
													class="btn btn-md fw-medium btn-whites text-dark">Logout</button></div>
										</div>
										<ul>
											<li><a href="my-profile.html"><i class="fa-regular fa-id-card me-2"></i>My Profile<span
														class="notti_coun style-1">4</span></a></li>
											<li><a href="my-booking.html"><i class="fa-solid fa-ticket me-2"></i>My Booking</a></li>
											<li><a href="travelers.html"><i class="fa-solid fa-user-group me-2"></i>Travelers</a></li>
											<li><a href="payment-detail.html"><i class="fa-solid fa-wallet me-2"></i>Payment Details</a></li>
											<li><a href="my-wishlists.html"><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</a></li>
											<li><a href="login.html"><i class="fa-solid fa-power-off me-2"></i>Sign Out</a></li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
          </div>
        </nav>
      </div>
    </div>
  
</template>

<script>
import CountriesModal from '@/components/nations/modals/CountriesModal';
import Logo from '@/assets/tsamaye.png';
    export default{
        name:'HeaderComponent',
		data() {
			return {
			Logo: Logo
			};
		},

        components: {
          CountriesModal,

        }
    }
</script>