<template>
    <BookingsPage/>
</template>

<script>
import BookingsPage from '@/components/bookings/BookingsPage';
    export default{
        name: 'BookingsView',
        components: {
            BookingsPage,
        }
    }
</script>