<template>
    <div id="main-wrapper">
        <HeaderComponent/>
        <AuthenticatedHeader/>
        <UserProfile/>
        <FooterSection/>
    </div>
</template>

<script>
import HeaderComponent from '@/components/navigation/HeaderComponent';
import AuthenticatedHeader from '@/components/navigation/AuthenticatedHeader'
import UserProfile from '@/components/profile/UserProfile';
import FooterSection from '@/components/footer/FooterSection';
    export default{
        name: 'ProfilePage',
        components:{
            HeaderComponent,
            AuthenticatedHeader,
            UserProfile,
            FooterSection,
        }
    }
</script>