<template>
    <WishPage/>
</template>

<script>
import WishPage from '@/components/wishlist/WishPage';
    export default{
        name: 'WishView',
        components: {
            WishPage,
        }
    }
</script>