<template>
    <section class="pt-5 gray-simple position-relative">
			<div class="container">

				<div class="row align-items-center justify-content-center">
					<div class="col-xl-12 col-lg-12 col-md-12 mb-4">
						<button class="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas"
							data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i
								class="fa-solid fa-gauge me-2"></i>Dashboard
							Navigation</button>
						<div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
							id="offcanvasDashboard" aria-labelledby="offcanvasScrollingLabel">
							<div class="offcanvas-header gray-simple">
								<h5 class="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
								<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
							</div>
							<div class="offcanvas-body p-0">
								<ul class="user-Dashboard-longmenu">
									<li><a href="my-profile.html"><i class="fa-regular fa-id-card me-2"></i>My Profile</a>
									</li>
									<li><a href="my-booking.html"><i class="fa-solid fa-ticket me-2"></i>My Booking</a>
									</li>
									<li><a href="travelers.html"><i class="fa-solid fa-user-group me-2"></i>Travelers</a></li>
									<li><a href="payment-detail.html"><i class="fa-solid fa-wallet me-2"></i>Payment Details</a></li>
									<li><a href="my-wishlists.html"><i class="fa-solid fa-shield-heart me-2"></i>My Wishlist</a></li>
									<li><a href="settings.html"><i class="fa-solid fa-sliders me-2"></i>Settings</a></li>
									<li class="active"><a href="delete-account.html"><i class="fa-solid fa-trash-can me-2"></i>Delete
											Profile</a></li>
									<li><a href="login.html"><i class="fa-solid fa-power-off me-2"></i>Sign Out</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="row align-items-start justify-content-between gx-xl-4">

					<div class="col-xl-12 col-lg-12 col-md-12">

						<!-- Personal Information -->
						<div class="card mb-4">
							<div class="card-header">
								<h4><i class="fa-solid fa-smile me-2"></i>Plan Upcoming Trip</h4>
							</div>
							<div class="card-body">
								<div class="eportledd mb-4">
									<h6>Save Your Data<span class="text-danger">*</span></h6>
									<div class="form-check ps-0">
										Take a backup of your data <a href="#" class="text-primary">Here</a>
									</div>
								</div>
								<form class="mb-3">
									<div class="row">
                                        <div class="col-md-6">
                                            <h6>Select Destination ( From Bookings )</h6>
                                            <select type="text" class="form-control mb-2">
                                                <option>New York</option>
                                            </select>
                                        </div>

                                        <div class="col-md-6">
                                            <h6>Whats your Budget</h6>
                                            <input type="text" class="form-control mb-2" placeholder="20,000.00">
                                        </div>

                                        <div class="col-md-6">
                                            <h6>Arrival Date</h6>
                                            <input type="date" class="form-control mb-2">
                                        </div>

                                        <div class="col-md-6">
                                            <h6>Departure Date</h6>
                                            <input type="date" class="form-control mb-2">
                                        </div>
                                    </div>
									
								</form>
								<div class="d-sm-flex justify-content-start">
									<button type="button" class="btn btn-md btn-primary fw-medium me-2 mb-0">Save</button>
									<a href="#" class="btn btn-md btn-light-primary fw-medium mb-0">Cancel</a>
								</div>

							</div>
						</div>

					</div>

				</div>
			</div>
		</section>
</template>