<template>
<div class="card rounded-2 me-xl-5 mb-4">
   <div class="card-top bg-primary position-relative">
      <div class="position-absolute end-0 top-0 mt-4 me-3"><a href="login.html"
         class="square--40 circle bg-light-dark text-light"><i
         class="fa-solid fa-right-from-bracket"></i></a></div>
      <div class="py-5 px-3">
         <div class="crd-capser text-center">
            <h6 class="mb-0 text-light text-md">Upcoming Trips</h6>
         </div>
      </div>
   </div>
   <div class="card-middle px-4 py-5" id="pills-tab" role="tablist">
      <div class="crdapproval-groups">
         <div class="crdapproval-single btn btn-light-seegreen d-flex align-items-center justify-content-start mb-4"
            id="pills-flsfirst-tab" data-bs-toggle="pill" data-bs-target="#pills-flsfirst" type="button" role="tab" aria-controls="pills-flsfirst"
            >
            <div class="crdapproval-item">
               <div class="square--50 circle bg-light-success text-success"><i
                  class="fa-solid fa-location-dot fs-5"></i></div>
            </div>
            <div class="crdapproval-caps ps-2">
               <p class="text-md text-dark lh-2 mb-0">England, London</p>
               <p class="text-md text-muted lh-1 mb-0">10 Aug 2022</p>
            </div>
         </div>
         <div class="crdapproval-single btn btn-light-seegreen d-flex align-items-center justify-content-start mb-4"
            data-bs-target="#pills-flssecond" type="button" role="tab" aria-controls="pills-flssecond"
            >
            <div class="crdapproval-item">
               <div class="square--50 circle bg-light-success text-success"><i
                  class="fa-solid fa-location-dot fs-5"></i></div>
            </div>
            <div class="crdapproval-caps ps-2">
               <p class="text-md text-dark lh-2 mb-0">Zimbabwe, Harare</p>
               <p class="text-md text-muted lh-1 mb-0">12 Aug 2022</p>
            </div>
         </div>
         <div class="crdapproval-single btn btn-light-seegreen d-flex align-items-center justify-content-start">
            <div class="crdapproval-item">
               <div class="square--50 circle bg-light-warning text-warning"><i
                  class="fa-solid fa-location-dot fs-5"></i></div>
            </div>
            <div class="crdapproval-caps ps-2">
               <p class="text-md text-dark lh-2 mb-0">South Africa, Durban</p>
               <p class="text-md text-muted lh-1 mb-0">Not Verified</p>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
    export default{
        name:'TripsCreated',
        components:{

        }
    }
</script>