<template>
    <div class="card mb-4">
        <div class="card-header">
            <h4><i class="fa-solid fa-file-invoice-dollar me-2"></i>Billing History</h4>
        </div>
        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amout</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th>01</th>
                    <td>BK32154</td>
                    <td>10 Sep 2023</td>
                    <td><span class="badge bg-light-success text-success fw-medium text-uppercase">Paid</span></td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>02</th>
                    <td>BK32155</td>
                    <td>08 Aug 2023</td>
                    <td><span class="badge bg-light-warning text-warning fw-medium text-uppercase">UnPaid</span></td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>03</th>
                    <td>BK32156</td>
                    <td>10 Aug 2023</td>
                    <td><span class="badge bg-light-info text-info fw-medium text-uppercase">Hold</span></td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>04</th>
                    <td>BK32157</td>
                    <td>22 Jul 2023</td>
                    <td><span class="badge bg-light-seegreen text-seegreen fw-medium text-uppercase">completed</span>
                    </td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>05</th>
                    <td>BK32158</td>
                    <td>16 Jun 2023</td>
                    <td><span class="badge bg-light-danger text-danger fw-medium text-uppercase">cancel</span></td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>06</th>
                    <td>BK32159</td>
                    <td>20 May 2023</td>
                    <td><span class="badge bg-light-info text-info fw-medium text-uppercase">hold</span></td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                    <tr>
                    <th>07</th>
                    <td>BK32160</td>
                    <td>18 Apr 2023</td>
                    <td><span class="badge bg-light-seegreen text-seegreen fw-medium text-uppercase">completed</span>
                    </td>
                    <td><span class="text-md fw-medium text-dark">$240</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default{
        name:'BillingHistory',
        components:{

        }
    }
</script>